/* Dashboard.css */

.dashboard {
    padding: 20px;
    overflow-y: auto;
    max-height: 50vh;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .chart-container {
    margin-bottom: 40px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  