.unified-filters {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .filter-group {
    margin-bottom: 20px;
  }
  
  .filter-group h4 {
    margin-bottom: 10px;
    font-size: 1.1em;
    color: #333;
  }
  
  label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
  }
  
  input[type="checkbox"] {
    margin-right: 10px;
  }
  
  select, input[type="range"] {
    width: 100%;
    padding: 5px;
    font-size: 1em;
    margin-top: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  input[type="range"] {
    cursor: pointer;
  }
  
  span {
    display: block;
    margin-top: 8px;
    font-size: 0.9em;
    color: #666;
  }
  