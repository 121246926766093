/* App Container */
.app-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

/* Sidebar Filters */
.sidebar-filters {
  width: 250px; /* Make the sidebar compact */
  background-color: #f1f1f1;
  padding: 10px;
  overflow-y: auto;
  border-right: 1px solid #ccc;
}

/* Unified Filters CSS (adjust padding and margins for compactness) */
.unified-filters {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.unified-filters .filter-group {
  margin-bottom: 15px;
}

.unified-filters h4 {
  font-size: 1em;
  margin-bottom: 5px;
}

.unified-filters input[type="checkbox"] {
  margin-right: 8px;
}

.unified-filters input[type="range"] {
  width: 100%;
}

/* Content Container */
.content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

/* Map and Ads List */
.map-and-list-container {
  display: flex;
  height: 60%; /* Adjust height */
}

.map-container {
  flex: 1;
  overflow: hidden;
}

.ads-list-container {
  width: 30%;
  overflow-y: auto;
  border-left: 1px solid #ccc;
  background-color: lightgrey;
}

/* Dashboard Section */
.dashboard-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #ccc;
}
