.ads-list {
    padding: 20px;
    overflow-y: auto;
    height: 100%;
    background-color: #f9f9f9; /* Light background for the list */
  }
  
  .ads-list h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
  }
  
  .ad-item {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 15px;
    transition: box-shadow 0.3s ease;
  }
  
  .ad-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .ad-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .ad-header h4 {
    margin: 0;
    font-size: 1.2em;
    color: #2c3e50;
  }
  
  .ad-type {
    font-size: 0.9em;
    padding: 3px 8px;
    border-radius: 4px;
    text-transform: capitalize;
  }
  
  .ad-type.rent {
    background-color: #3498db;
    color: #fff;
  }
  
  .ad-type.sell {
    background-color: #e74c3c;
    color: #fff;
  }
  
  .ad-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .ad-info, .ad-stats {
    flex: 1;
  }
  
  .ad-info p, .ad-stats p {
    margin: 5px 0;
    font-size: 0.9em;
    color: #555;
  }
  
  .ad-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .ad-address {
    font-size: 0.9em;
    color: #777;
    font-style: italic;
  }
  
  .view-button {
    background-color: #2ecc71;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 0.9em;
    transition: background-color 0.3s ease;
  }
  
  .view-button:hover {
    background-color: #27ae60;
  }
  
  .load-more-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .load-more-button:hover {
    background-color: #2980b9;
  }
  