/* App Container */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

/* Unified Filters Container */
.unified-filters {
  padding: 15px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
  z-index: 100;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1); /* Optional: Adds slight shadow to visually separate filters */
}

/* Content Container */
.content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  height: calc(100vh - 70px); /* Ensure the content container has the correct height, considering filter height */
}

/* Map and Ads List Container */
.map-and-list-container {
  display: flex;
  flex-grow: 1;
  height: 100%; /* Ensure the map and ads list take full height */
  min-height: 0;
  padding-top: 10px; /* Adds space above the map */
}

/* Map Container */
.map-container {
  flex: 1;
  position: relative;
  height: 100%; /* Ensure map takes full height */
  min-height: 0;
  overflow: hidden;
  margin-left: 10px; /* Optional: Adds slight margin to the map to avoid it being attached to the sides */
}

/* Ads List Container */
.ads-list-container {
  width: 30%;
  overflow-y: auto;
  border-left: 1px solid #ccc;
  background-color: lightgrey;
}

/* Dashboard Section */
.dashboard-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #ccc;
}
